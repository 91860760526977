import styled, { css } from 'styled-components'

import { BadgeCheckSVG } from "@icons"
import { injectFontStyle } from '@typography/helpers'

const OrderedList = styled.ol`
  ${({ theme }) => css`
    ${BadgeCheckSVG} {
      display: none;
    }

    li::marker {
      color: ${theme.colors.neutral70};
      ${injectFontStyle(theme, 'body1')}
    }
  `}
`

const UnorderedBadgeCheckList = styled.ul`
  ${({ theme }) => css`
    display: grid;
    list-style: none;
    margin: 0;
    padding: 0;
    row-gap: ${theme.sizes.s3};

    ${BadgeCheckSVG} {
      fill: ${theme.colors.primary40};
      max-height: 28px;
      max-width: 28px;
      min-height: 28px;
      min-width: 28px;
      padding-right: ${theme.sizes.s3};

      path {
        fill: ${theme.colors.primary40};
      }
    }

    li {
      display: flex;
    }
  `}
`

export { OrderedList, UnorderedBadgeCheckList }