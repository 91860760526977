import React, { useContext } from 'react'
import { has, get, find, includes, split, map } from 'lodash'
import GlobalContext from '@components/Layout/GlobalContext'
import { logos } from '@components/Layout/layoutHelpers'

import { sanitizeTrailingSlash } from './sanitizeTrailingSlash'
import { getSuffixByLocale } from './TranslationHelpers'

const resourcesCategories = [
  'research reports',
  'guides & ebooks',
  'case studies',
  'integrations',
  'podcasts',
]

const blogCategories = [
  'company',
  'deliverability',
  'dev life',
  'development',
  'email',
  'email best practices',
  'it & engineering',
  'marketing',
  'product',
]

const isArticleFromBlog = (pageContent) => {
  if (has(pageContent, 'content.categoryCodeId')) {
    return includes(blogCategories, pageContent.content.categoryCodeId)
  }
  return false
}

const isArticleFromResources = (pageContent) => {
  if (has(pageContent, 'content.categoryCodeId')) {
    return includes(resourcesCategories, pageContent.content.categoryCodeId)
  }
  return false
}

const isVideoArticle = (pageContent) =>
  pageContent.content.categoryCodeId === 'videos & webinars'

const getTitleFromResourcesArticle = ({ headerSection, title, titleHeader }) =>
  titleHeader || headerSection?.title || title || 'no title found'

const generateJsonldBreadcrumb = (hrefLangData, locale) => {
  const { breadcrumbData } = useContext(GlobalContext)
  const nativeUrlData = find(
    hrefLangData,
    (hrefLang) => hrefLang.hrefLang === locale,
  )
  const currentUrl = nativeUrlData && new URL(nativeUrlData.href)

  const itemListElementArray = map(breadcrumbData, ({ label, link }, index) => {
    return {
      '@type': 'ListItem',
      position: index + 1,
      name: label,
      item: currentUrl.origin + link,
    }
  })
  if (!itemListElementArray) return

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: itemListElementArray,
  }
}

export const generateJsonldBreadcrumbAndArticle = ({
  pageContext,
  hrefLangData,
  brandName = 'Mailjet',
  isBreadcrumbPages,
}) => {
  const isArticlePage = get(pageContext, 'pageContent.content.isArticle', false)
  if (!isArticlePage && !isBreadcrumbPages) return

  const { pageContent, locale } = pageContext
  const { content } = pageContent
  const jsonld = []

  if (isArticlePage) {
    const cardImage = 'https:' + get(content, 'card.firstItem.picture.file.url')
    const siteUrl = `https://www.${brandName.toLowerCase()}.com`

    if (isArticleFromBlog(pageContent)) {
      const languagePath = getSuffixByLocale(locale)
      const authors = map(content.authors, (author) => ({
        '@type': 'Person',
        name: author?.secondItem?.title,
        url: sanitizeTrailingSlash(
          siteUrl +
            languagePath +
            'blog/author/' +
            split(author?.name?.toLowerCase(), ' author')[0].replace(/ /g, '-'),
        ),
      }))

      jsonld.push({
        '@context': 'https://schema.org',
        '@type': 'Article',
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': sanitizeTrailingSlash(siteUrl + content?.url),
        },
        headline: content?.headerSection?.title,
        image: cardImage,
        author: authors.length > 1 ? authors : authors[0],
        publisher: {
          '@type': 'Organization',
          name: brandName,
          logo: {
            '@type': 'ImageObject',
            url: logos[brandName],
          },
        },
        dateModified: content?.date,
      })
    } else if (isArticleFromResources(pageContent)) {
      const author = get(content, 'authors[0].secondItem.title')
      const headline = getTitleFromResourcesArticle(content)
      const imageUrl = cardImage || logos[brandName]
      const datePublished = get(content, 'date') || get(content, 'createdAt')
      const dateModified = get(content, 'updatedAt')

      jsonld.push({
        '@context': 'https://schema.org',
        '@type': 'Article',
        headline,
        image: [imageUrl],
        datePublished,
        dateModified,
        author: {
          '@type': author ? 'Person' : 'Organization',
          name: author || brandName,
        },
        publisher: {
          '@type': 'Organization',
          name: brandName,
          logo: {
            '@type': 'ImageObject',
            url: logos[brandName],
          },
        },
      })
    }
    //  TODO podcasts schema with condition of course
    else if (isVideoArticle(pageContent)) {
      jsonld.push({
        '@context': 'https://schema.org',
        '@type': 'VideoObject',
        name: content?.title,
        description: content?.titleTranscript,
        thumbnailUrl: [content?.card?.firstItem?.picture?.file?.url],
        uploadDate: content?.createdAt,
        /*
          Setup video duration but must setup ctf field or force ctf filler to use a normalized
          string to convert string in ISO8601
          "duration": "PT1M54S", See later with ctf...
        */
        contentUrl: siteUrl + content?.url,
      })
    }
  }
  if (isBreadcrumbPages) {
    jsonld.push(generateJsonldBreadcrumb(hrefLangData, locale))
  }

  return (
    <script type='application/ld+json'>
      {jsonld.length > 1 ? JSON.stringify(jsonld) : JSON.stringify(jsonld[0])}
    </script>
  )
}
