const { capitalize } = require('lodash')
const { styleForMailgun } = require('./Gatsby-node/siteArchitectureHelpers')

const getAboutThisEpisode = () => ({
  'en-US': 'About this episode:',
  'fr-FR': 'A propos de cet épisode :',
  'de-DE': 'Über diese Episode:',
  'es-ES': 'Sobre este episodio:',
})

const getBlogSearchPlaceholder = () => ({
  'en-US': 'Search Blog',
  'fr-FR': 'Rechercher dans le blog',
  'de-DE': 'Suche Blog',
  'es-ES': 'Buscar en el blog',
})

const getExploreChapters = () => ({
  'en-US': 'Explore chapters',
  'fr-FR': 'Découvrir les chapitres',
  'de-DE': 'Kapitel erkunden',
  'es-ES': 'Explorar los capítulos',
})

const getIntegrationsSearchPlaceholder = () => ({
  'en-US': 'Search integrations',
  'fr-FR': 'Rechercher des intégrations',
  'de-DE': 'Integrationen suchen',
  'es-ES': 'Buscar integraciones',
})

const getResourcesSearchPlaceholder = () => ({
  'en-US': 'Search resources',
  'fr-FR': 'Rechercher des ressources',
  'de-DE': 'Ressourcen suchen',
  'es-ES': 'Buscar recursos',
})

const getSearchPlaceholder = () => ({
  'en-US': 'Search',
  'fr-FR': 'Rechercher',
  'de-DE': 'Suche',
  'es-ES': 'Busca en',
})

const getTacticSearchPlaceholder = () => ({
  'en-US': 'Search tactics',
  'fr-FR': 'Rechercher une stratégie',
  'de-DE': 'Nach Taktik suchen',
  'es-ES': 'Búsqueda de estrategias',
})

const getViewFullFeatures = () => ({
  'en-US': 'View full features',
  'fr-FR': 'Voir les caractéristiques détaillées', // TODO
  'de-DE': 'Zu den Abonnements',
  'es-ES': 'Ver todas las funciones',
})

const getReleaseNoteSearchPlaceholder = () => ({
  'en-US': 'Search a release note',
  'fr-FR': 'Trouver une note de version',
  'de-DE': 'Finden Sie den Versionshinweis',
  'es-ES': 'Buscar una nota de lanzamiento',
})

const getResourcesSearchCategoriesPlaceholder = (category) => ({
  'en-US': 'Search ' + category,
  'fr-FR': 'Rechercher des ' + category,
  'de-DE': capitalize(category) + ' suchen',
  'es-ES': 'Buscar ' + category,
})

const getDifficulty = () => ({
  'en-US': 'Difficulty',
  'fr-FR': 'Difficulté',
  'de-DE': 'Schwierigkeitsgrad',
  'es-ES': 'Dificultad',
})

const getDifficultiesValue = () => ({
  Beginner: {
    'en-US': 'Beginner',
    'fr-FR': 'Débutant',
    'de-DE': 'Einfach',
    'es-ES': 'Principiante',
  },
  Intermediate: {
    'en-US': 'Intermediate',
    'fr-FR': 'Intermédiaire',
    'de-DE': 'Mittel',
    'es-ES': 'Intermedia',
  },
  Advanced: {
    'en-US': 'Advanced',
    'fr-FR': 'Avancé',
    'de-DE': 'Fortgeschritten',
    'es-ES': 'Avanzada',
  },
})

const getGlossary = () => ({
  'en-US': 'Glossary',
  'fr-FR': 'Glossaire',
  'de-DE': 'Glossar',
  'es-ES': 'Glosario',
})

const getRelevantResources = () => ({
  'en-US': 'Relevant resources',
  'fr-FR': 'Ressources supplémentaires pertinentes',
  'de-DE': 'Relevante Ressourcen',
  'es-ES': 'Recursos relevantes',
})

const getPreviousTactic = () => ({
  'en-US': 'Previous tactic',
  'fr-FR': 'Stratégie précédente',
  'de-DE': 'Vorherige Taktik',
  'es-ES': 'Estrategia anterior',
})

const getNextTactic = () => ({
  'en-US': 'Next tactic',
  'fr-FR': 'Stratégie suivante',
  'de-DE': 'Nächste Taktik',
  'es-ES': 'Siguiente estrategia',
})

const getNextTerm = () => ({
  'en-US': 'Next term',
  'fr-FR': 'Terme suivant',
  'de-DE': 'Nächster Begriff',
  'es-ES': 'Próximo término',
})

const getPreviousTerm = () => ({
  'en-US': 'Previous term',
  'fr-FR': 'Terme précédent',
  'de-DE': 'Vorheriger Begriff',
  'es-ES': 'Término anterior',
})

const getShareTo = () => ({
  'en-US': 'Share to',
  'fr-FR': 'Partager', // TODO
  'de-DE': 'Teilen auf',
  'es-ES': 'Compártelo',
})

const getShowLess = () => ({
  'en-US': 'Show less',
  'fr-FR': 'Montrer moins',
  'de-DE': 'Weniger anzeigen',
  'es-ES': 'Mostrar menos',
})

const getShowMore = () => ({
  'en-US': 'Show more',
  'fr-FR': 'Voir plus',
  'de-DE': 'Mehr anzeigen',
  'es-ES': 'Mostrar más',
})

const getShowMoreCategoryTactics = (category) => ({
  'en-US': 'Show more ' + category + ' tactics',
  'fr-FR': 'Montrer plus de tactiques de ' + category,
  'de-DE': 'Weitere Taktiken der ' + category,
  'es-ES': 'Mostrar más tácticas de' + category,
})

const getSeeAll = () => ({
  'en-US': 'See all',
  'fr-FR': 'Voir tous',
  'de-DE': 'Alle sehen',
  'es-ES': 'Ver todo',
})

const getSeeAllTacticsByCategory = () => ({
  'contact list': {
    'en-US': 'See all Contact list tactics',
    'fr-FR': 'Voir toutes les stratégies sur les listes de contacts',
    'de-DE': 'Alle Kontaktlisten-Taktiken anzeigen',
    'es-ES': 'Ver todas las estrategias de Lista de contactos',
  },
  deliverability: {
    'en-US': 'See all Deliverability tactics',
    'fr-FR': 'Voir toutes les stratégies de délivrabilité',
    'de-DE': 'Alle Zustellbarkeits-Taktiken ansehen',
    'es-ES': 'Ver todas las estrategias de Entregabilidad',
  },
  'open rates': {
    'en-US': 'See all Open rates tactics',
    'fr-FR': "Voir toutes les stratégies sur les taux d'ouverture",
    'de-DE': 'Alle Öffnungsraten-Taktiken ansehen',
    'es-ES': 'Ver todas las estrategias de Tasas de aperturas',
  },
  'click rates': {
    'en-US': 'See all Click rates tactics',
    'fr-FR': 'Voir toutes les stratégies sur les taux de clics',
    'de-DE': 'Alle Klickraten-Taktiken anzeigen',
    'es-ES': 'Ver todas las estrategias de Tasas de clics',
  },
  design: {
    'en-US': 'See all Design tactics',
    'fr-FR': 'Voir toutes les stratégies de design',
    'de-DE': 'Alle Design-Taktiken anzeigen',
    'es-ES': 'Ver todas las estrategias de Diseño',
  },
  lifecycle: {
    'en-US': 'See all Lifecycle tactics',
    'fr-FR': 'Voir toutes les stratégies sur le cycle de vie client',
    'de-DE': 'Alle Lifecycle-Taktiken ansehen',
    'es-ES': 'Ver todas las estrategias de Ciclo de vida',
  },
})

const getSeeAllReports = () => ({
  'en-US': 'See all reports',
  'fr-FR': 'Voir tous les rapports de recherche',
  'de-DE': 'Alle Berichte anzeigen',
  'es-ES': 'Ver todos los informes',
})

const getSeeAllGuidesAndEbooks = () =>
  styleForMailgun()
    ? {
      'en-US': 'See all guides',
      'fr-FR': 'Voir tous les guides',
      'de-DE': 'Alle Reiseführer anzeigen',
      'es-ES': 'Ver todas las guías',
    }
    : {
      'en-US': 'See all guides and ebooks',
      'fr-FR': 'Voir tous les guides et ebooks',
      'de-DE': 'Alle Leitfäden und E-Books anzeigen',
      'es-ES': 'Ver todas las ebooks y guías',
    }

const getSeeAllVideosAndWebinars = () => ({
  'en-US': 'See all videos and webinars',
  'fr-FR': 'Voir toutes les vidéos et les webinaires',
  'de-DE': 'Alle Videos und Webinare anzeigen',
  'es-ES': 'Ver todos los vídeos y seminarios web',
})

const getSeeAllPodcasts = () => ({
  'en-US': 'See all podcasts',
  'fr-FR': 'Voir tous les podcasts',
  'de-DE': 'Alle Podcasts anzeigen',
  'es-ES': 'Ver todos los podcasts',
})

const getSeeAllCaseStudies = () => ({
  'en-US': 'See all case studies',
  'fr-FR': 'Voir toutes les études de cas',
  'de-DE': 'Alle Fallstudien anzeigen',
  'es-ES': 'Ver todos los casos prácticos',
})

const getSeeAllMarketing = () => ({
  'en-US': 'See all Marketing posts',
  'fr-FR': 'Voir tous les articles sur le marketing',
  'de-DE': 'Mehr Marketing-Artikel anzeigen',
  'es-ES': 'Ver todos los artículos de marketing',
})

const getSeeAllEmail = () => ({
  'en-US': 'See all Email posts',
  'fr-FR': 'Voir tous les articles sur l’emailing',
  'de-DE': 'Mehr Email-Artikel anzeigen',
  'es-ES': 'Ver todos los artículos de emailing',
})

const getSeeAllDeliverability = () => ({
  'en-US': 'See all deliverability',
  'fr-FR': 'Voir tous les résultats',
  'de-DE': 'Alle Zustellbarkeiten anzeigen',
  'es-ES': 'Ver toda la entregabilidad',
})

const getSeeAllLatestStories = () => ({
  'en-US': 'See all latest stories',
  'fr-FR': 'Voir tous les articles récents',
  'de-DE': 'Mehr Artikel anzeigen',
  'es-ES': 'Ver todos los artículos recientes',
})

const getResourcesCategories = () => ({
  'en-US': [
    'Research reports',
    styleForMailgun() ? 'Guides' : 'Guides and ebooks',
    'Videos and webinars',
    'Podcasts',
    'Case studies',
  ],
  'fr-FR': [
    'Rapports de recherche',
    styleForMailgun() ? 'Guides' : 'Guides et ebooks',
    'Vidéos et webinaires',
    'Podcasts',
    'Études de cas',
  ],
  'de-DE': [
    'Berichte',
    styleForMailgun() ? 'Leitfäden' : 'Leitfäden und E-Books',
    'Videos und Webinare',
    'Podcasts',
    'Fallstudien',
  ],
  'es-ES': [
    'Informes',
    styleForMailgun() ? 'Guías' : 'Ebooks y guías',
    'Vídeos y seminarios web',
    'Podcasts',
    'Estudios de caso',
  ],
})

const getRelatedResources = () => ({
  'en-US': 'Related resources',
  'fr-FR': 'Ressources associées',
  'de-DE': 'Verwandte Ressourcen',
  'es-ES': 'Recursos relacionados',
})

const getReadMore = () => ({
  'en-US': 'Read More',
  'fr-FR': 'En savoir plus',
  'de-DE': 'Mehr lesen',
  'es-ES': 'Leer más',
})

const getViewIntegration = () => ({
  'en-US': 'View integration',
  'fr-FR': "Voir l'intégration",
  'de-DE': 'Integration anzeigen',
  'es-ES': 'Ver integración',
})

const getExample = () => ({
  'en-US': 'Example',
  'fr-FR': 'Exemple',
  'de-DE': 'Beispiel',
  'es-ES': 'Ejemplo',
})

const getResult = () => ({
  'en-US': 'Result',
  'fr-FR': 'Résultats',
  'de-DE': 'Ergebnis',
  'es-ES': 'Resultado',
})

const getLearnMore = () => ({
  'en-US': 'Learn More',
  'fr-FR': 'En savoir plus',
  'de-DE': 'Mehr erfahren',
  'es-ES': 'Más información',
})

const getFeatured = () => ({
  'en-US': 'Featured',
  'fr-FR': "À l'honneur",
  'de-DE': 'Ausgewählt',
  'es-ES': 'Destacado',
})

const getCompanySize = () => ({
  'en-US': 'Company size',
  'fr-FR': "Taille de l'entreprise",
  'de-DE': 'Größe des Unternehmens',
  'es-ES': 'Tamaño de la empresa',
})

const getIndustry = () => ({
  'en-US': 'Industry',
  'fr-FR': 'Industrie',
  'de-DE': 'Industrie',
  'es-ES': 'Industria',
})

const getBusinessChallenge = () => ({
  'en-US': 'Business challenge',
  'fr-FR': 'Défi commercial',
  'de-DE': 'Geschäftliche Herausforderung',
  'es-ES': 'Reto empresarial',
})

const getRegion = () => ({
  'en-US': 'Region',
  'fr-FR': 'Région',
  'de-DE': 'Region',
  'es-ES': 'Región',
})

const getUseCase = () => ({
  'en-US': 'Use case',
  'fr-FR': "Cas d'utilisation",
  'de-DE': 'Anwendungsfall',
  'es-ES': 'Caso de uso',
})

const getProduct = () => ({
  'en-US': 'Product',
  'fr-FR': 'Produit',
  'de-DE': 'Produkt',
  'es-ES': 'Producto',
})

const getKeyMetrics = () => ({
  'en-US': 'Key metrics',
  'fr-FR': 'Chiffres clés',
  'de-DE': 'Wichtige Kennzahlen',
  'es-ES': 'Métricas clave',
})

const getPlaybookHomeLabel = () => ({
  'en-US': 'Email Growth Playbook',
  'fr-FR': 'Le guide ultime de l’emailing',
  'de-DE': 'Handbuch für E-Mail-Wachstum',
  'es-ES': 'Centro de estrategias de email',
})

const getFirstIntegrationsBreadcrumb = () => ({
  'en-US': 'Resources',
  'fr-FR': 'Ressources',
  'de-DE': 'Ressourcen',
  'es-ES': 'Recursos',
})

const getSecondIntegrationsBreadcrumb = () => ({
  'en-US': [
    'All integrations',
    'CMS',
    'Connectors',
    'Contact management',
    'CRM',
    'E-Commerce',
    'Marketing',
    'Platforms',
    'Mailjet',
    'Mailgun',
  ],
  'fr-FR': [
    'Toutes les intégrations',
    'CMS',
    'Connectors',
    'Contact management',
    'CRM',
    'E-Commerce',
    'Marketing',
    'Platforms',
    'Mailjet',
    'Mailgun',
  ],
  'de-DE': [
    'Alle Integrationen',
    'CMS',
    'Connectors',
    'Contact management',
    'CRM',
    'E-Commerce',
    'Marketing',
    'Platforms',
    'Mailjet',
    'Mailgun',
  ],
  'es-ES': [
    'Todas las integraciones',
    'CMS',
    'Connectors',
    'Contact management',
    'CRM',
    'E-Commerce',
    'Marketing',
    'Platforms',
    'Mailjet',
    'Mailgun',
  ],
})

const getAll = () => ({
  'en-US': 'All',
  'fr-FR': 'Tous',
  'de-DE': 'Alle',
  'es-ES': 'Todo',
})

const getSubMenuButtonsResources = () => ({
  'en-US': {
    all: getAll()['en-US'],
    'research-reports': 'Research reports',
    guides: styleForMailgun() ? 'Guides' : 'Guides and ebooks',
    videos: 'Videos and webinars',
    podcasts: 'Podcasts',
    'case-studies': 'Case studies',
    integrations: 'Integrations',
  },
  'fr-FR': {
    all: getAll()['fr-FR'],
    'research-reports': 'Rapports de recherche',
    guides: styleForMailgun() ? 'Guides' : 'Guides et ebooks',
    videos: 'Vidéos et webinaires',
    podcasts: 'Podcasts',
    'case-studies': 'Études de cas',
    integrations: 'Intégrations',
  },
  'de-DE': {
    all: getAll()['de-DE'],
    'research-reports': 'Forschungsberichte',
    guides: styleForMailgun() ? 'Leitfäden' : 'Leitfäden und E-Books',
    videos: 'Videos und Webinare',
    podcasts: 'Podcasts',
    'case-studies': 'Fallstudien',
    integrations: 'Integrationen',
  },
  'es-ES': {
    all: getAll()['es-ES'],
    'research-reports': 'Informes de investigación',
    guides: styleForMailgun() ? 'Guías' : 'Ebooks y guías',
    videos: 'Vídeos y seminarios web',
    podcasts: 'Podcasts',
    'case-studies': 'Estudios de caso',
    integrations: 'Integraciones',
  },
})

const getAllReports = () => ({
  'en-US': 'All reports',
  'fr-FR': 'Tous les rapports',
  'de-DE': 'Alle Berichte',
  'es-ES': 'Todos los informes',
})

const getAllVideosAndWebinars = () => ({
  'en-US': 'All videos and webinars',
  'fr-FR': 'Toutes les vidéos et webinaires',
  'de-DE': 'Alle Videos und Webinare',
  'es-ES': 'Todos los vídeos y seminarios web',
})

const getAllGuidesAndEbooks = () =>
  styleForMailgun()
    ? {
      'en-US': 'All guides',
      'fr-FR': 'Tous les guides',
      'de-DE': 'Alle leitfäden',
      'es-ES': 'Todas las guías',
    }
    : {
      'en-US': 'All guides and ebooks',
      'fr-FR': 'Tous les guides et ebooks',
      'de-DE': 'Alle leitfäden und ebooks',
      'es-ES': 'Todas las ebooks y guías',
    }

const getAllCaseStudies = () => ({
  'en-US': 'All case studies',
  'fr-FR': 'Toutes les études de cas',
  'de-DE': 'Alle Fallstudien',
  'es-ES': 'Todos los casos prácticos',
})

const getAllPresentersTitles = () => ({
  'en-US': 'Meet your presenters',
  'fr-FR': 'Rencontrez vos speakers',
  'de-DE': 'Treffen Sie Ihre Moderatoren',
  'es-ES': 'Conoce a tus presentadoras',
})

const getAllNoteAndRefTitles = () => ({
  'en-US': 'Show notes and resources',
  'fr-FR': 'Ressources',
  'de-DE': 'Notizen und Ressourcen anzeigen',
  'es-ES': 'Mostrar notas y recursos',
})

const getFilter = () => ({
  'en-US': 'Filter',
  'fr-FR': 'Filtre',
  'de-DE': 'Filter',
  'es-ES': 'Filtro',
})

const getOverview = () => ({
  'en-US': 'Overview',
  'fr-FR': 'Aperçu',
  'de-DE': 'Übersicht',
  'es-ES': 'Resumen',
})

const getTranscript = (category) => ({
  'en-US': category + ' transcript',
  'fr-FR': 'Transcription de ' + category,
  'de-DE': category + '-Transkript',
  'es-ES': 'Transcripción del ' + category,
})

const getVideo = () => ({
  'en-US': 'Video',
  'fr-FR': 'Vidéo',
  'de-DE': 'Video',
  'es-ES': 'Vídeo',
})

const getWebinar = () => ({
  'en-US': 'Webinar',
  'fr-FR': 'Webinaire',
  'de-DE': 'Webinar',
  'es-ES': 'Webinar',
})

const getCodeEditorMessage = () => ({
  textCopyButton: {
    'en-US': 'Copy to clipboard',
    'fr-FR': 'Copier dans le presse-papier',
    'de-DE': 'In die Zwischenablage kopieren',
    'es-ES': 'Copiar al portapapeles',
  },
  tooltipMessageCopied: {
    'en-US': 'Copied',
    'fr-FR': 'Copié',
    'de-DE': 'Kopiert',
    'es-ES': 'Copiado',
  },
  tooltipMessageCopy: {
    'en-US': 'Copy',
    'fr-FR': 'Copie',
    'de-DE': 'Kopieren',
    'es-ES': 'Dupdo',
  },
})

const getTableOfContent = () => ({
  'en-US': 'Table of contents',
  'fr-FR': 'Table des matières',
  'de-DE': 'Inhaltsverzeichnis',
  'es-ES': 'Tabla de contenidos',
})

const getSubMenuButtonsMailjetBlog = () => ({
  'en-US': [
    'Home',
    'Marketing',
    'Email best practices',
    'Deliverability',
    'Development',
    'Product',
    'Company',
  ],
  'fr-FR': [
    'Accueil',
    'Marketing',
    'Bonnes pratiques emailing',
    'Délivrabilité',
    'Développeurs',
    'Produit',
    'Entreprise',
  ],
  'de-DE': [
    'Startseite',
    'Marketing',
    'E-Mail',
    'Zustellbarkeit',
    'Entwickler',
    'Produkt',
    'Unternehmen',
  ],
  'es-ES': [
    'Inicio',
    'Marketing',
    'Emailing',
    'Entregabilidad',
    'Desarrolladores',
    'Producto',
    'Empresa',
  ],
})

const getSubMenuButtonsMailgunBlog = () => ({
  'en-US': [
    'Home',
    'Email',
    'Deliverability',
    'IT & Engineering',
    'Product',
    'Dev Life',
    'Company',
    'Jobs',
  ],
  'fr-FR': [
    'Accueil',
    'Email',
    'Deliverability',
    'IT & Engineering',
    'Product',
    'Dev Life',
    'Company',
    'Jobs',
  ],
  'de-DE': [
    'Startseite',
    'Email',
    'Deliverability',
    'IT & Engineering',
    'Product',
    'Dev Life',
    'Company',
    'Jobs',
  ],
  'es-ES': [
    'Inicio',
    'Email',
    'Deliverability',
    'IT & Engineering',
    'Product',
    'Dev Life',
    'Company',
    'Jobs',
  ],
})

const getPopularPosts = () => ({
  'en-US': 'Popular posts',
  'fr-FR': 'Articles populaires',
  'de-DE': 'Beliebte Beiträge',
  'es-ES': 'Puestos populares',
})

const getRelatedReadings = () => ({
  'en-US': 'Related readings',
  'fr-FR': 'Lectures associées',
  'de-DE': 'Verwandte Lektüre',
  'es-ES': 'Lecturas relacionadas',
})

const getRecentArticle = () => ({
  'en-US': 'Recent articles',
  'fr-FR': 'Articles récents',
  'de-DE': 'Aktuelle Artikel',
  'es-ES': 'Artículos Recientes',
})

const getLatestStories = () => ({
  'en-US': 'Latest stories',
  'fr-FR': 'Articles récents',
  'de-DE': 'Neueste Artikel',
  'es-ES': 'Artículos recientes',
})

const getFirst = () => ({
  'en-US': 'First',
  'fr-FR': 'Premier',
  'de-DE': 'Erste',
  'es-ES': 'Primero',
})

const getLast = () => ({
  'en-US': 'Last',
  'fr-FR': 'Dernier',
  'de-DE': 'Zuletzt',
  'es-ES': 'Última',
})

const getAuthor = () => ({
  'en-US': 'Author',
  'fr-FR': 'Auteur',
  'de-DE': 'Autor',
  'es-ES': 'Autor',
})

const getLatestStoriesBy = (name) => ({
  'en-US': 'Latest stories by ' + name,
  'fr-FR': 'Articles récents par ' + name,
  'de-DE': 'Neueste Geschichten von ' + name,
  'es-ES': 'Últimas noticias de ' + name,
})

const getCategory = () => ({
  'en-US': 'Category',
  'fr-FR': 'Categorie',
  'de-DE': 'Kategorie',
  'es-ES': 'Categoría',
})

const getRecommendedIntegrations = () => ({
  'en-US': 'Recommended integrations',
  'fr-FR': 'Intégrations recommandées',
  'de-DE': 'Empfohlene Integrationen',
  'es-ES': 'Integraciones recomendadas',
})

const getPreviousIntegration = () => ({
  'en-US': 'Previous Integration',
  'fr-FR': 'Intégration Précédente',
  'de-DE': 'Vorherige Integration',
  'es-ES': 'Integración Previa',
})

const getNextIntegration = () => ({
  'en-US': 'Next Integration',
  'fr-FR': 'Intégration Suivante',
  'de-DE': 'Nächste Integration',
  'es-ES': 'Siguiente Integración',
})

const getIntegrationArticleSearchPlaceholder = () => ({
  'en-US': 'Search integrations',
  'fr-FR': 'Rechercher des intégrations',
  'de-DE': 'Suchintegrationen',
  'es-ES': 'Integraciones de búsqueda',
})

const getCategories = () => ({
  'en-US': 'Categories',
  'fr-FR': 'Catégories',
  'de-DE': 'Kategorien',
  'es-ES': 'Categorías',
})

const getPrice = () => ({
  'en-US': 'Price',
  'fr-FR': 'Prix',
  'de-DE': 'Preis',
  'es-ES': 'Precio',
})

const getDeveloper = () => ({
  'en-US': 'Developer',
  'fr-FR': 'Développeur',
  'de-DE': 'Entwickler',
  'es-ES': 'Desarrollador',
})

const getRequirements = () => ({
  'en-US': 'Requirements',
  'fr-FR': 'Exigences',
  'de-DE': 'Anforderungen',
  'es-ES': 'Requisitos',
})

const getGuide = () => ({
  'en-US': 'Guide',
  'fr-FR': 'Guide',
  'de-DE': 'Leitfaden',
  'es-ES': 'Guía',
})

const getMoreResources = () => ({
  'en-US': 'More resources',
  'fr-FR': 'Plus de ressources',
  'de-DE': 'Mehr Ressourcen',
  'es-ES': 'Más recursos',
})

const getFeatures = () => ({
  'en-US': 'Features',
  'fr-FR': 'Caractéristiques',
  'de-DE': 'Eigenschaften',
  'es-ES': 'Características ',
})

const getInstallations = () => ({
  'en-US': 'Installations',
  'fr-FR': 'Installations',
  'de-DE': 'Installationen',
  'es-ES': 'Instalaciones',
})
const getGetStarted = () => ({
  'en-US': 'Get Started',
  'fr-FR': 'Commencer',
  'de-DE': 'Starten',
  'es-ES': 'Empezar',
})

const getTalkToAnExpert = () => ({
  'en-US': 'Talk To An Expert',
  'fr-FR': 'Parler A Un Expert',
  'de-DE': 'Experten kontaktieren',
  'es-ES': 'Habla Con Un Experto',
})

const getHideMe = () => ({
  'en-US': 'Hide me',
  'fr-FR': 'Cachez-moi',
  'de-DE': 'Versteck mich',
  'es-ES': 'Esconder',
})

const getShowMe = () => ({
  'en-US': 'Show table',
  'fr-FR': 'Afficher les tables',
  'de-DE': 'Tabelle anzeigen',
  'es-ES': 'Mostrar tabla',
})

const getNoResult = () => ({
  'en-US': 'No result',
  'fr-FR': 'Aucun résultat',
  'de-DE': 'Kein Ergebnis',
  'es-ES': 'No hay resultados',
})

const getPer100SMS = () => ({
  'en-US': 'Per 100 SMS',
  'fr-FR': 'Pour 100 SMS',
  'de-DE': 'Pro 100 SMS',
  'es-ES': 'Por 100 SMS',
})

const getCustom = () => ({
  'en-US': 'Custom',
  'fr-FR': 'Sur devis',
  'de-DE': 'Angebot anfordern',
  'es-ES': 'Solicitar presupuesto',
})

const getMonthly = () => ({
  'en-US': 'Monthly',
  'fr-FR': 'Mensuel',
  'de-DE': 'Monatlich',
  'es-ES': 'Mensual',
})

const getAnnual10PrctDiscount = () => ({
  'en-US': 'Annual (10% discount)',
  'fr-FR': 'Annuel (10% de réduction)',
  'de-DE': 'Jährlich (10% Rabatt)',
  'es-ES': 'Anual (10% de descuento)',
})

const getSMSPricing = () => ({
  'en-US': 'SMS Pricing',
  'fr-FR': 'Voir les tarifs SMS',
  'de-DE': 'SMS Preise anzeigen',
  'es-ES': 'Ver tarifas para SMS',
})

const getMore = () => ({
  'en-US': 'More',
  'fr-FR': 'Plus',
  'de-DE': 'Mehr',
  'es-ES': 'Más',
})

const getFree = () => ({
  'en-US': 'Free',
  'fr-FR': 'Gratuit',
  'de-DE': 'Free',
  'es-ES': 'Gratuito',
})

const getEssential = () => ({
  'en-US': 'Essential',
  'fr-FR': 'Essentiel',
  'de-DE': 'Essential',
  'es-ES': 'Esencial',
})

const getEnterprise = () => ({
  'en-US': 'Enterprise',
  'fr-FR': 'Entreprise',
  'de-DE': 'Unternehmen',
  'es-ES': 'Empresa',
})

const getEmailMarketingPricing = () => ({
  'en-US': 'Email Marketing Pricing',
  'fr-FR': "Prix de l'email marketing",
  'de-DE': 'E-Mail-Marketing-Preise',
  'es-ES': 'Precios de Email Marketing',
})

const getEmailAPIPricing = () => ({
  'en-US': 'Email API Pricing',
  'fr-FR': "Prix de l'API de messagerie",
  'de-DE': 'E-Mail-API-Preise',
  'es-ES': 'Precios de la API de Email',
})

const getNotYetACustomer = () => ({
  'en-US': 'Not yet a customer?',
  'fr-FR': "Vous n'êtes pas encore client?",
  'de-DE': 'Sie sind noch kein Kunde?',
  'es-ES': '¿Aún no es cliente?',
})

const getSignUpHere = () => ({
  'en-US': 'Sign up here.',
  'fr-FR': 'Inscrivez-vous ici.',
  'de-DE': 'Melden Sie sich hier an.',
  'es-ES': 'Inscríbase aquí.',
})

const getAllIntegrations = () => ({
  'en-US': 'All Integrations',
  'fr-FR': 'Toutes les intégrations',
  'de-DE': 'Alle Integrationen',
  'es-ES': 'Todas las integraciones',
})

const getBackTo = () => ({
  'en-US': 'Back to',
  'fr-FR': 'Retour au',
  'de-DE': 'Zurück zum',
  'es-ES': 'Volver al',
})

const getBackToPage = () => ({
  'en-US': 'Back to page',
  'fr-FR': 'Retour à la page',
  'de-DE': 'Zurück zur Seite',
  'es-ES': 'Volver a la página',
})

const getChapter = () => ({
  'en-US': 'Chapter',
  'fr-FR': 'Chapitre',
  'de-DE': 'Kapitel',
  'es-ES': 'Capítulo',
})

const getChapterSelection = () => ({
  'en-US': 'Chapter selection',
  'fr-FR': 'Sélection du chapitre',
  'de-DE': 'Auswahl der Kapitel',
  'es-ES': 'Selección de capítulos',
})

const getChapterWithNumber = (number, hasDot = false) => {
  if (number === 0) {
    return {
      'en-US': 'Introduction' + (hasDot ? ': ' : ''),
      'fr-FR': 'Introduction' + (hasDot ? ' : ' : ''),
      'de-DE': 'Vorwort' + (hasDot ? ': ' : ''),
      'es-ES': 'Introducción' + (hasDot ? ': ' : ''),
    }
  } else {
    return {
      'en-US': 'Chapter ' + number + (hasDot ? ': ' : ''),
      'fr-FR': 'Chapitre ' + number + (hasDot ? ' : ' : ''),
      'de-DE': 'Kapitel ' + number + (hasDot ? ': ' : ''),
      'es-ES': 'Capítulo ' + number + (hasDot ? ': ' : ''),
    }
  }
}

const getImpossibleToLoad = () => ({
  'en-US': 'Impossible to load',
  'fr-FR': 'Chargement impossible',
  'de-DE': 'Unmöglich zu laden',
  'es-ES': 'Imposible de cargar',
})

const getMainMenu = () => ({
  'en-US': 'main menu',
  'fr-FR': 'menu principal',
  'de-DE': 'hauptmenü',
  'es-ES': 'menú principal',
})

const getHome = () => ({
  'en-US': 'Home',
  'fr-FR': 'Accueil',
  'de-DE': 'Startseite',
  'es-ES': 'Inicio',
})

const getAnd = () => ({
  'en-US': 'and',
  'fr-FR': 'et',
  'de-DE': 'und',
  'es-ES': 'y',
})

const getIntegrations = () => ({
  'en-US': 'Integrations',
  'fr-FR': 'Intégrations',
  'de-DE': 'Integrationen',
  'es-ES': 'Integraciones',
})

const getNext = () => ({
  'en-US': 'Next',
  'fr-FR': 'Suivant',
  'de-DE': 'Next',
  'es-ES': 'Siguiente',
})

const getNextChapter = () => ({
  'en-US': 'Next Chapter',
  'fr-FR': 'Chapitre Suivant',
  'de-DE': 'Nächstes Kapitel',
  'es-ES': 'Capítulo Siguiente',
})

const getPrevious = () => ({
  'en-US': 'Previous',
  'fr-FR': 'Précédent',
  'de-DE': 'Vorherige',
  'es-ES': 'Anterior',
})

const getPreviousChapter = () => ({
  'en-US': 'Previous Chapter',
  'fr-FR': 'Chapitre Précédent',
  'de-DE': 'Vorheriges Kapitel',
  'es-ES': 'Capítulo Anterior',
})

const getReadingTime = () => ({
  'en-US': 'Reading Time: ',
  'fr-FR': 'Temps de lecture: ',
  'de-DE': 'Lesezeit: ',
  'es-ES': 'Tiempo de lectura: ',
})

const getGDPR = () => ({
  'en-US': 'GDPR',
  'fr-FR': 'RGPD',
  'de-DE': 'DSGVO',
  'es-ES': 'RGPD',
})

const getUSDollars = () => ({
  'en-US': '$ US Dollars',
  'fr-FR': '$ Dollars américains',
  'de-DE': '$ US-Dollar',
  'es-ES': '$ dólares americanos',
})

const getEuros = () => ({
  'en-US': '€ Euro',
  'fr-FR': '€ Euros',
  'de-DE': '€ Euros',
  'es-ES': '€ euros',
})

const getBritishPounds = () => ({
  'en-US': '£ British Pounds',
  'fr-FR': '£ Livres sterling',
  'de-DE': '£ Britische Pfund',
  'es-ES': '£ libras esterlinas',
})

const getProTip = () => ({
  'en-US': 'Pro Tip',
  'fr-FR': 'Astuce',
  'de-DE': 'Pro-Tipp',
  'es-ES': 'Un paso más',
})

const getPublishedOnText = () => ({
  'en-US': `PUBLISHED ON `,
  'es-ES': ``,
  'fr-FR': ``,
  'de-DE': ``,
})

const getEmailNotValidatedMessage = () => ({
  'en-US': `Please enter a valid email address.`,
  'es-ES': `Por favor, introduce una dirección de correo electrónico válida.`,
  'fr-FR': `Veuillez saisir une adresse email valide.`,
  'de-DE': `Bitte geben Sie eine gültige E-Mail-Adresse ein.`,
})

const getSelectYouCurrency = () => ({
  'en-US': `Select your currency:`,
  'es-ES': `Elige tu divisa:`,
  'fr-FR': `Choisissez votre devise :`,
  'de-DE': `Wählen Sie Ihre Währung aus:`,
})

const getReset = () => ({
  'en-US': 'Reset',
  'fr-FR': 'Réinitialiser',
  'de-DE': 'Zurücksetzen',
  'es-ES': 'Restablecer',
})

const getPressRelease = () => ({
  'en-US': 'Press release',
  'fr-FR': 'Communiqué de presse',
  'de-DE': 'Presseerklärung',
  'es-ES': 'Comunicado de prensa',
})

const getPressReleases = () => ({
  'en-US': 'Press releases',
  'fr-FR': 'Communiqués de presse',
  'de-DE': 'Presseveröffentlichungen',
  'es-ES': 'Comunicados de prensa',
})

const getInTheNews = () => ({
  'en-US': ' in the news',
  'fr-FR': " dans l'actualité",
  'de-DE': ' in den Nachrichten',
  'es-ES': ' en las noticias',
})

const getSeeMore = () => ({
  'en-US': 'See more',
  'fr-FR': 'Voir plus',
  'de-DE': 'Mehr sehen',
  'es-ES': 'Ver más',
})

const getNews = () => ({
  'en-US': 'News',
  'fr-FR': 'Actualités',
  'de-DE': 'Nachrichten',
  'es-ES': 'Noticias',
})

const getPreviousRelease = () => ({
  'en-US': 'Previous release',
  'fr-FR': 'Note précédente',
  'de-DE': 'Vorherige Versionshinweis',
  'es-ES': 'Nota anterior',
})

const getCompareAllFeatures = () => ({
  'en-US': 'Compare All Features',
  'fr-FR': 'Comparer toutes les fonctionnalités',
  'de-DE': 'Alle Funktionen vergleichen',
  'es-ES': 'Comparar todas las funciones',
})

const getHideTables = () => ({
  'en-US': 'Hide Tables',
  'fr-FR': 'Masquer les tableaux',
  'de-DE': 'Tabellen ausblenden',
  'es-ES': 'Ocultar las tablas',
})

const getNextRelease = () => ({
  'en-US': 'Next release',
  'fr-FR': 'Note suivante',
  'de-DE': 'Nächste Versionshinweis',
  'es-ES': 'Siguiente nota',
})

const getPricingSendMG = () => ({
  'en-US': 'Mailgun Send Pricing',
  'fr-FR': 'Tarif Mailgun Send',
  'de-DE': 'Mailgun Send Preise',
  'es-ES': 'Precios de Mailgun',
})

const getPricingOptimizeMG = () => ({
  'en-US': 'Mailgun Optimize Pricing',
  'fr-FR': 'Tarif Mailgun Optimize',
  'de-DE': 'Mailgun Optimize Preise',
  'es-ES': 'Precios de Mailgun Optimize',
})

const getYouMightAlsoLike = () => ({
  'en-US': 'You might also like',
  'fr-FR': 'Vous pourriez aussi aimer',
  'de-DE': 'Das könnte Sie auch interessieren',
  'es-ES': 'También te puede gustar',
})

module.exports = {
  getAboutThisEpisode,
  getAll,
  getAllCaseStudies,
  getAllGuidesAndEbooks,
  getAllIntegrations,
  getAllNoteAndRefTitles,
  getAllPresentersTitles,
  getAllReports,
  getAllVideosAndWebinars,
  getAnd,
  getAnnual10PrctDiscount,
  getAuthor,
  getBackTo,
  getBackToPage,
  getBlogSearchPlaceholder,
  getBritishPounds,
  getBusinessChallenge,
  getCategories,
  getCategory,
  getChapter,
  getChapterSelection,
  getChapterWithNumber,
  getCodeEditorMessage,
  getCompanySize,
  getCompareAllFeatures,
  getCustom,
  getDeveloper,
  getDifficulty,
  getDifficultiesValue,
  getEmailAPIPricing,
  getEmailMarketingPricing,
  getEmailNotValidatedMessage,
  getEnterprise,
  getEssential,
  getExploreChapters,
  getEuros,
  getExample,
  getFeatured,
  getFeatures,
  getFilter,
  getFirst,
  getFirstIntegrationsBreadcrumb,
  getFree,
  getGDPR,
  getGetStarted,
  getGlossary,
  getGuide,
  getHideMe,
  getHideTables,
  getHome,
  getImpossibleToLoad,
  getIndustry,
  getIntegrations,
  getInTheNews,
  getInstallations,
  getIntegrationArticleSearchPlaceholder,
  getIntegrationsSearchPlaceholder,
  getKeyMetrics,
  getLast,
  getLatestStories,
  getLatestStoriesBy,
  getLearnMore,
  getMainMenu,
  getMonthly,
  getMore,
  getMoreResources,
  getNews,
  getNext,
  getNextChapter,
  getNextIntegration,
  getNextRelease,
  getNextTactic,
  getNextTerm,
  getNoResult,
  getNotYetACustomer,
  getOverview,
  getPer100SMS,
  getPlaybookHomeLabel,
  getPopularPosts,
  getPressRelease,
  getPressReleases,
  getPrevious,
  getPreviousChapter,
  getPreviousIntegration,
  getPreviousRelease,
  getPreviousTactic,
  getPreviousTerm,
  getPrice,
  getPricingSendMG,
  getPricingOptimizeMG,
  getProduct,
  getProTip,
  getPublishedOnText,
  getReadingTime,
  getReadMore,
  getViewIntegration,
  getRecentArticle,
  getRecommendedIntegrations,
  getRegion,
  getRelatedReadings,
  getRelatedResources,
  getReleaseNoteSearchPlaceholder,
  getRelevantResources,
  getRequirements,
  getReset,
  getResourcesCategories,
  getResourcesSearchCategoriesPlaceholder,
  getResourcesSearchPlaceholder,
  getResult,
  getSearchPlaceholder,
  getSecondIntegrationsBreadcrumb,
  getSeeAll,
  getSeeAllCaseStudies,
  getSeeAllDeliverability,
  getSeeAllEmail,
  getSeeAllGuidesAndEbooks,
  getSeeAllLatestStories,
  getSeeAllMarketing,
  getSeeAllPodcasts,
  getSeeAllReports,
  getSeeAllTacticsByCategory,
  getSeeAllVideosAndWebinars,
  getSeeMore,
  getSelectYouCurrency,
  getShareTo,
  getShowLess,
  getShowMe,
  getShowMore,
  getShowMoreCategoryTactics,
  getSignUpHere,
  getSMSPricing,
  getSubMenuButtonsMailgunBlog,
  getSubMenuButtonsMailjetBlog,
  getSubMenuButtonsResources,
  getTacticSearchPlaceholder,
  getTableOfContent,
  getTalkToAnExpert,
  getTranscript,
  getUSDollars,
  getUseCase,
  getVideo,
  getViewFullFeatures,
  getWebinar,
  getYouMightAlsoLike,
}
